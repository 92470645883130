.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20vh;
}

.bp-layout {
  display: flex;
  flex-direction: row;
  max-width: 2048px;
  min-width: 1024px;
  width: 90%;
  margin: 20px auto 0px auto;
  padding: 0px 20px;
}

.bp-main {
  width: 100%;
  padding-right: 30px;
}

.bp-sider {
  flex-basis: 720px;
}

.loading-text {
  margin-top: 2vh;
}

.bp-top-microphone {
  display: flex;
  max-width: 2048px;
  min-width: 1024px;
  width: 90%;
  margin: 20px auto 0px auto;
  padding: 0px 20px;
  align-items: center;
}

.bp-vertical-allignment {
  display: flex;
  flex-direction: column;
}