html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.container {
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow:
    0 0.333333px 16px rgba(0, 0, 0, 0.01),
    0 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  max-width: 1024px;
}

.container--large {
  max-width: 2048px;
  min-width: 1024px;
  width: 90%;
}

.container__section {
  margin: 0 !important;
}

.field {
  display: flex;
  align-items: center;
}

.field__label {
  margin-right: 10px;
}

.label--h {
  padding-bottom: 15px;
  display: block;
}

.label--title {
  padding-bottom: 15px;
  display: block;
  font-weight: bold;  
}

.flex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}