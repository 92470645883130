.snooze-config-row {
  position: relative;
}

.close-icon {
  visibility: hidden;
  transition: visibility 0.2s;
  margin-left: 10px;
}

.snooze-config-row:hover .close-icon {
  visibility: visible;
}