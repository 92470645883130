.offline-page-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    flex-direction: column;
    padding-top: 20px;
}

.extra-large-spin .ant-spin-dot {
  font-size: 50px;
}

.offline-page-content {
    color: black;
}