.profile-page {
  max-width: fit-content;
  margin-inline: auto;
}

.profile-title {
}

.progress-part {
  display: flex;
  flex-direction: row;
}

.profile-info {
  display: flex;
  flex-direction: row;
}

.ant-progress-bg {
  height: 40px !important;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  color: currentColor !important;
}

.ant-carousel .slick-prev::before {
  color: currentColor !important;
}

.slick-arrow.slick-prev {
  font-size: 20px !important;
}

.ant-carousel .slick-next::before {
  color: currentColor !important;
}

.slick-arrow.slick-next {
  font-size: 20px !important;
}

.ant-carousel .slick-dots li button {
  background: #474747;
  opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #474747;
}

.btn-grad-gold {
  background-image: linear-gradient(90deg, #e0b44f, #fce7b4);
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: black;
  box-shadow: 0 0 20px #eee;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #e0b44f;
  display: block;
}

.btn-grad-bronze {
  background-image: linear-gradient(90deg, #ffac5b, #ffddbc);
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: black;
  box-shadow: 0 0 20px #eee;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #ffac5b;
  display: block;
}

.btn-grad-silver {
  background-image: linear-gradient(90deg, #474747, #9c9a9a);
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #474747;
  display: block;
}

.btn-grad-prime {
  background-color: #1677ff;
  text-align: center;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #1677ff;
  display: block;
}
