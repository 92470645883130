/* Page header section */

.bp-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1280px;
  width: 100%;
}

.bp-header__title {
  margin: 0;
}

.bp-header__status-text {
  margin: 0 15px 0 0;
}

.bp-header__status-color {
  background-color: #50c878;
  border-radius: 15px;
  width: 15px;
  height: 15px;
  margin-right: 15px;
}

.bp-header__status-color--offline {
  background-color: #ff4500;
}

.bp-header__status {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* ------------------------- */

.bp-microphone-red {
  color: red;
  margin: 5px 5px 5px 15px;
}

.bp-microphone-green {
  color: green;
  margin: 5px 5px 5px 15px;
}

.bp-microphone-black {
  color: black;
  margin: 5px 5px 5px 15px;
}

.bp-recording-text {
  color: gray;
  margin: auto auto auto 23px;
}

.bp-recording-time {
  margin: 5px;
}

.bp {
  width: 1280px;
  margin: 15px auto 0 auto;
}

.bp-section {
  background-color: #fff;
  box-shadow: 0 0.333333px 16px rgba(0, 0, 0, 0.01), 0 1.25px 5px rgba(0, 0, 0, 0.06);
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.bp-section-recording-hidden {
  display: none;
  visibility: hidden;
}

.bp-section-recording {
  background-color: #fff;
  box-shadow: 0 0.333333px 16px rgba(0, 0, 0, 0.01), 0 1.25px 5px rgba(0, 0, 0, 0.06);
  width: 100%;
  padding: 11px 5px 11px 5px;
  box-sizing: border-box;
}

.bp-header {
  margin: 15px 0;
  padding: 15px 20px;
}

.bp-body {
  display: flex;
  margin-bottom: 15px;
}

.bp-customer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.bp-customer__title {
  margin: 0 0 5px 0;
}

.bp-customer__text {
  margin: 0;
}

.bp-content {
  margin-right: 15px;
  flex-basis: 70%;
}

.bp-sales-script {
  overflow-y: auto;
  height: 77vh;
  padding-left: 10px;
  padding-right: 25px;
}

.bp__objection {
  border: solid 1px #d9d9d9;
  background-color: #fcfcfc;
  width: 100%;
  padding: 5px;
  margin: 0 0 5px 0;
  cursor: pointer;
  border-radius: 3px;
}

.bp__objection-answer {
  margin: 0;
  border: solid 1px #d9d9d9;
  background-color: #fcfcfc;
  padding: 5px 10px;
}

.bp__objection-answer-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bp-script {
  flex-basis: 40%;
}

.bp-calendar {
  padding-top: 0;
  margin-top: 15px;
}

.bp-comment {
  display: flex;
  margin-bottom: 15px;
  flex-direction: 'column';
}

.bp-calendar .cal-page {
  margin: 0;
  padding: 0;
  box-shadow: none;
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: initial;
}

.bp-calendar__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.bp-calendar__buttons > * {
  flex-basis: 25%;
}

.bp-cards__container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.bp-card {
  width: 400px;
  height: 100px;
}

.bp-card-body {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.bp-card-body--flipped {
  transform: rotateY(180deg);
}

.bp-card-front,
.bp-card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 1px rgb(244, 244, 244);
  background-color: rgb(252, 252, 252);
  border-radius: 3px;
  padding: 15px;
  box-sizing: border-box;
}

.bp-card-back {
  transform: rotateY(180deg);
}

.bp-comments {
  display: flex;
  gap: 10px;
}

.bp-comment__header {
  font-size: 12px;
}

.bp-comment__body {
  margin: 0;
  font-size: 14px;
}

.offline-page {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  color: black;
}

.customizable-btn {
  font-size: 14px;
}
.btn-grad-gold {
  background-image: linear-gradient(90deg, #e0b44f, #fce7b4);
}
.btn-grad-gold {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: black;
  box-shadow: 0 0 20px #eee;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #e0b44f;
  display: block;
}
.btn-grad-gold:hover {
  background-position: right center; /* change the direction of the change here */
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}

.btn-grad-bronze {
  background-image: linear-gradient(90deg, #ffac5b, #ffddbc);
}
.btn-grad-bronze {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: black;
  box-shadow: 0 0 20px #eee;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #ffac5b;
  display: block;
}
.btn-grad-bronze:hover {
  background-position: right center; /* change the direction of the change here */
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}

.btn-grad-silver {
  background-image: linear-gradient(90deg, #474747, #9c9a9a);
}
.btn-grad-silver {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #474747;
  display: block;
}
.btn-grad-silver:hover {
  background-position: right center; /* change the direction of the change here */
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
