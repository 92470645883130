.admin-container {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0.333333px 16px rgba(0, 0, 0, 0.01),
    0 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.admin-border {
  box-shadow: 0 0.333333px 16px rgba(0, 0, 0, 0.01),
    0 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 0 12px;
}

.admin-container-borderless {
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
}

.centered-container {
  text-align: center;
}

.flex-admin-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  margin: 15px 0px 15px 0px;
}

.flex-admin-vertical-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btn-admin {
  width: 200px;
  flex: 1;
  text-align: center;
}
