.bp-customer-section-vm-hint {
  background-color: #ffff89;
}

.bp-customer-title {
  font-weight: bold;
  margin-top: 0;
}

.bp-customer-section {
  display: grid;
  grid-template-columns: repeat(2, 3fr) 1fr;
  grid-template-rows: 35px repeat(3, auto);
  grid-column-gap: 10px;
  grid-row-gap: 15px;
}

.bp-wrong-phone-number {
  font-size: 18px;
  align-items: center;
  height: 32px;
  padding: 2px 6px;
  border-radius: 6px;
  margin: 0 0 0 5px;
}

.bp-customer_icon_container {
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
}

.bp-customer-section__buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.bp-customer-last-app {
  grid-area: 4 / 1 / 4 / 4;
  background-color: #d6e4ff;
  height: 35px;
  border-radius: 7px;
}

.bp-customer-lost-reason {
  grid-area: 4 / 1 / 5 / 4;
  color: white;
  border-radius: 7px;
}
