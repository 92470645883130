.header {
  box-shadow: rgba(114, 98, 98, 0.12) 0px -1px 0px 0px inset;
  background-color: #fff;
  display: flex;
  padding: 10px 75px;
  justify-content: space-between;
}

.header__logo {
  display: flex;
  align-items: center;
}

.header__title {
  font-weight: 500;
  font-size: 16px;
  margin-left: 8px;
}

.header__img {
  height: 40px;
}

.header__lang {
  margin-right: 10px;
}

.header__avatar-icon {
  cursor: pointer;
}

.header__countdown {
  margin-right: 20px;
}

.sales-channel-header {
  position: absolute;
  left: 0;
  margin-left: 21em;
  background-color: #a9aaa9;
  color: white;
  padding: 2px 8px;
  border-radius: 5px;
  font-weight: 500;
  
  font-size: 13px;
}