.empty-container {
  width: 800px;
  margin: 25px auto;
  min-height: 100vh;
  padding: 20px;
}

.cal-page {
  margin: 25px auto;
  min-height: 100vh;
  padding: 0 20px;
  box-shadow:
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(7, 37, 67, 0.2) 0px 0px 50px 0px;
}

.cal-params {
  padding: 15px 0;
}

.cal-week__user-col {
  display: flex;
  align-items: end;
  font-size: 16px;
  padding-bottom: 5px;
  justify-content: end;
  cursor: default;
}

.cal-params__language {
  justify-content: end;
}

.cal-week__icon {
  font-size: 25px;
  margin-right: 5px;
  margin-left: 10px;
}

.cal-params__col {
  display: flex;
  flex-direction: column;
}

.cal-params__arrows {
  justify-content: center;
  cursor: pointer;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: solid 1px rgb(26, 81, 193);
  background-color: rgb(62, 126, 255);
  color: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.calendar-header__day {
  padding: 15px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  justify-content: space-between;
}

.calendar-header__weekDay {
  font-weight: 700;
}

.calendar-body {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr max-content;
  grid-auto-flow: column;
  margin-top: -1px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px);
  background-size: 16.666667% 42px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  border-right: solid 1px rgba(0, 0, 0, 0.1);
}

.calendar-slot {
  background-color: rgb(236, 242, 255);
  margin: 5px;
  border: solid 1px rgba(0, 0, 0, 0.2);
}

.calendar-slot--field-sales {
  background-color: #FFC300;
}

.calendar-slot:hover {
  background-color: rgb(196, 212, 255) !important;
  color: #000 !important;
  border: solid 1px rgba(0, 0, 0, 0.2) !important;
}

.calendar-header__count,
.calendar-slot__count {
  position: absolute;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  top: 3px;
  right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px rgb(209, 224, 255);
  background-color: #fff;
  font-size: 9px;
}

.calendar-header__count {
  color: #000;
  top: 10px;
  right: 10px;
}

.calendar-slot__count:hover {
  background-color: #f5f5f5;
}

.calendar-slot__time {
  margin: 5px;
  font-size: 15px;
}

.calendar-slot__buttons {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.calendar-body__empty {
  padding-top: 50px;
}
