.confirmation-page {
  display: flex;
  flex-direction: row;
}

.cp-scripts {
    min-width: 450px;
    margin-left: 20px;
}

.cp-section {
  background-color: #fff;
  box-shadow:
    0 0.333333px 16px rgba(0, 0, 0, 0.01),
    0 1.25px 5px rgba(0, 0, 0, 0.06);
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.confirmation-page__buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.confirmation-page__title {
  padding: 0;
  margin: 0;
}

.confirmation-page .bp-section {
  box-shadow: initial;
  padding: initial;
}
