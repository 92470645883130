.conflicts-header {
  display: flex;
  justify-content: space-between;
}

.conflicts__rebooking {
  display: flex;
  align-items: center;
}

.conflicts__rebooking-counter {
  padding-right: 15px;
}
