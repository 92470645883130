.clp-download-btn{
  margin-bottom: 15px;
  float: right;
  align-self: center;
}

.clp-horizontal-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}