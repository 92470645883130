.activity-page {
  padding-left: 50px;
  padding-right: 50px;
  min-width: 1200px;
}

.activity-card {
  margin-bottom: 24px;
  border-color: #ede6e6;
  border-width: 2px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.activity-title {
  margin-left: 47px;
}

.ranking-section {
  margin-bottom: 24px;
}

.ranking-section .ant-card-head-title {
  font-weight: bold;
}

.warning-section {
  margin-bottom: 24px;
  background-color: rgb(252, 213, 213);
  box-shadow: 0 0.333333px 16px rgba(0, 0, 0, 0.01), 0 1.25px 5px rgba(0, 0, 0, 0.06);
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
}

.warning-message {
  font-weight: bold;
}
