.reached-not-booked__radio-group {
  font-size: 15px;
}

.reached-not-booked-input {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 50%;
}

.reached-not-booked-label {
  display: block;
  width: 100%;
}
