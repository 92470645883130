.flex-inbound-container {
}

.container-max-width {
  min-width: 1200px;
  margin: 0 60px 20px 60px;
}

.inbound-calendar {
  padding-top: 0;
  margin-top: 15px;
}

.inbound-container-border {
  box-shadow: 0 0.333333px 16px #00000005, 0 1.25px 5px #0000002f;
  border-radius: 4px;
}

.inbound-main-btns {
  grid-area: 1 / 5 / 1 / -1;
  min-width: 290px;
  display: grid;
  justify-content: end;
  grid-auto-flow: column;
  gap: 10px;
}

.inbound-grid-container {
  display: grid;
  grid-template-columns: repeat(4, 2fr) 2fr;
  grid-template-rows: 20px repeat(3, auto);
  grid-column-gap: 10px;
  grid-row-gap: 15px;
}

.inbound-booking-state {
  font-weight: normal;
  font-size: small;
  padding: 0 10px;
  border-radius: 3px;
}
